import axios from "axios";
import { COD_E_0002 } from "../../helpers/Mensajes";

const loginAPI = async (params) => {
  try {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_BACK_URL}/login`,
      data: {
        user: params.username,
        password: params.password,
        cedula: params.tempCedula,
      },
      params: {
        XDEBUG_SESSION_START: 'PHPSTORM'
      }
    };
    //consultamos el servicio de autenticacion
    const responseFarma = await axios(options);
    const { data } = responseFarma;

    if ("status" in data && data.status === "success") {
      const user = data.data;
      //Construmios el json con los datos que se almacenaran en sesion
      const responseJson = {
        //id: params.tempCedula, //TODO: En produccion comentar esta linea
        id: user.cedula,
        username: user.nombrecorto,
        usuarioNombres: user.usuarioNombres,
        usuarioEmail: user.usuarioEmail,
        token: `Bearer ${user.token}`,
        codigoCentroCosto: user.codigoCentroCosto,
        centroCosto: user.centroCosto,
        codigoSucursal: user.codigoSucursal,
        nombreSucursal: user.nombreSucursal,
        codigoOficina: user.codigoOficina,
        nombreOficina: user.nombreOficina,
        cedulaJefeInmediato: user.usuarioCedulaJefeInmediato,
        jefeInmediato: user.usuarioJefeInmediato,
        emailJefeInmediato: user.usuarioEmailJefeInmediato,
        usuarioUser: user.usuarioUser,
        gerenteCedula: user.gerenteCedula,
        gerenteNombres: user.gerenteNombres,
        gerenteEmail: user.gerenteEmail,
        cargaMasiva: user.cargaMasiva,
        cargoNombre: user.cargoNombre,
      };
      return {
        status: "success",
        data: responseJson,
      };
    }
  } catch (error) {
    if (error.response) {
      return {
        status: "Code Error",
        data: error.response.data.message,
      };
    }
    //error en el servicio de Colgados Llamados atencion
    if (error.config.url === `${process.env.REACT_APP_BACK_URL}/login`) {
      return {
        status: "Network Error",
        data: COD_E_0002,
      };
    }
  }
};

export default loginAPI;
