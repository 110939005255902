// @flow
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { fetchJSON } from "../../helpers/api";

import { LOGIN_USER, LOGOUT_USER } from "./constants";

import { loginUserSuccess, loginUserFailed } from "./actions";

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
  let cookies = new Cookies();
  if (user) cookies.set("user", JSON.stringify(user), { path: "/" });
  else cookies.remove("user", { path: "/" });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, tempCedula } }) {
  const options = {
    body: JSON.stringify({ username, password, tempCedula }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = yield call(fetchJSON, "/users/authenticate", options);
    setSession(response);
    yield put(loginUserSuccess(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(loginUserFailed(message));
    setSession(null);
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push("/account/login");
      history.go(0);
    });
  } catch (error) {}
}

export function* watchLoginUser(): any {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
  yield takeEvery(LOGOUT_USER, logout);
}

function* authSaga(): any {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}

export default authSaga;
