import loginAPI from "./loginAPI";

export function Authenticate() {
  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      if (url.endsWith("/users/authenticate") && opts.method === "POST") {
        let params = JSON.parse(opts.body);

        loginAPI(params)
          .then((response) => {
            if (response.status === "success") {
              resolve({ ok: true, json: () => response.data });
            } else {
              reject(response.data);
            }
          })
          .catch((error) => {
            reject(error);
          });

        return;
      }
      // pass through any requests not handled above
      realFetch(url, opts).then((response) => resolve(response));
    });
  };
}
