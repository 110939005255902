import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import { isUserAuthenticated, getProfile } from "../helpers/authUtils";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
// dashboard
const Dashboard = React.lazy(() => import("../pages/dashboards/Dashboard"));
//administracion
const EnunciadosGrid = React.lazy(() =>
  import("../pages/administracion/enunciados/EnunciadosGrid")
);
const CausasGrid = React.lazy(() =>
  import("../pages/administracion/causas/CausasGrid")
);
const Imagenes = React.lazy(() =>
  import("../pages/administracion/imagenes/Imagenes")
);
const AccionesGrid = React.lazy(() =>
  import("../pages/administracion/acciones/AccionesGrid")
);
const FormulariosGrid = React.lazy(() =>
  import("../pages/administracion/formularios/FormulariosGrid")
);
const TthhGrid = React.lazy(() =>
  import("../pages/administracion/tthh/TthhGrid")
);
const PermisoCargaMasivaGrid = React.lazy(() =>
  import("../pages/administracion/cargaMasiva/PermisoCargaMasivaGrid")
);
const OrigenesGrid = React.lazy(() =>
  import("../pages/administracion/origenes/OrigenesGrid")
);
const SolicitanteOrigenesGrid = React.lazy(() =>
  import("../pages/administracion/solicitante_origenes/SolicitanteOrigenGrid")
);
//Rutas para formularios
const EnunciadoForm = React.lazy(() =>
  import("../pages/administracion/enunciados/EnunciadosForm")
);
const CausasForm = React.lazy(() =>
  import("../pages/administracion/causas/CausasForm")
);
const AccionesForm = React.lazy(() =>
  import("../pages/administracion/acciones/AccionesForm")
);
const FormulariosForm = React.lazy(() =>
  import("../pages/administracion/formularios/FormulariosForm")
);
const CrearLlamadoForm = React.lazy(() =>
  import("../pages/gerente/CrearLlamadoForm")
);
const LlamadoForm = React.lazy(() =>
  import("../pages/colaborador/LlamadoForm")
);
const MemorandumDetalle = React.lazy(() =>
  import("../pages/colaborador/MemorandumDetalle")
);
const LlamadoFormColaborador = React.lazy(() =>
  import("../pages/colaborador/LlamadoFormColaborador")
);
const ErroresEnunciados = React.lazy(() =>
  import("../pages/administracion/enunciados/ErroresEnunciados")
);
const VerErrores = React.lazy(() =>
    import("../pages/administracion/VerErrores")
);
const CheckList = React.lazy(() => import("../pages/colaborador/CheckList"));
const FinalizadoForm = React.lazy(() =>
  import("../pages/gerente/FinalizadoForm")
);
const TthhForm = React.lazy(() =>
  import("../pages/administracion/tthh/TthhForm")
);
const PermisoCargaMasivaForm = React.lazy(() =>
  import("../pages/administracion/cargaMasiva/PermisoCargaMasivaForm")
);
const OrigenesForm = React.lazy(() =>
  import("../pages/administracion/origenes/OrigenesForm")
);
const SolicitanteOrigenesForm = React.lazy(() =>
  import("../pages/administracion/solicitante_origenes/SolicitanteOrigenForm")
);
//colaborador
const LlamadosColaboradorGrid = React.lazy(() =>
  import("../pages/colaborador/LlamadosColaboradorGrid")
);
const HistorialColaboradorGrid = React.lazy(() =>
    import("../pages/gerente/HistorialLlamadosGrid")
);
const HistorialMemoColaboradorGrid = React.lazy(() =>
    import("../pages/gerente/HistorialMemoGrid")
);
//gerente
const LlamadosGerenteGrid = React.lazy(() =>
  import("../pages/gerente/LlamadosGerenteGrid")
);
const DelegarGrid = React.lazy(() => import("../pages/gerente/DelegarGrid"));
const DelegarForm = React.lazy(() => import("../pages/gerente/DelegarForm"));
const PermisoGrid = React.lazy(() => import("../pages/gerente/PermisoGrid"));
const PermisoForm = React.lazy(() => import("../pages/gerente/PermisoForm"));
//Seleccionar Perfil
const SeleccionarPerfil = React.lazy(() =>
  import("../pages/auth/SeleccionarPerfil")
);
//Reportes
const ReporteGeneral = React.lazy(() =>
  import("../pages/administracion/reportes/ReporteGeneral")
);
const ReporteDescuentos = React.lazy(() =>
  import("../pages/administracion/reportes/ReporteDescuentos")
);
const ReporteResumen = React.lazy(() =>
  import("../pages/administracion/reportes/ReporteResumen")
);
const ReporteColaborador = React.lazy(() =>
  import("../pages/administracion/reportes/ReporteColaborador")
);
const ReporteMemorandum = React.lazy(() =>
  import("../pages/administracion/reportes/ReporteMemorandum")
);
//Formulario Colaborador
const FormularioColaborador = React.lazy(() =>
  import("../pages/gerente/VerFormularioForm")
);
//Memorandums
const MemoSolicitanteGrid = React.lazy(() =>
  import("../pages/gerente/MemoSolicitanteGrid")
);
const MemoColaboradorGrid = React.lazy(() =>
  import("../pages/colaborador/MemoColaboradorGrid")
);
const ReporteCausasEnun = React.lazy(() =>
    import("../pages/administracion/reportes/ReporteCausasEnun")
);

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{
              pathname: "/account/logout",
              state: { from: props.location },
            }}
          />
        );
      }

      // check if route is restricted by role
      if (roles && roles.indexOf(getProfile()) === -1) {
        //Si el rol es multiple redirigimos
        if (getProfile() === "Multiple") {
          return <Redirect to={{ pathname: "/perfil" }} />;
        }
        //Si el rol es delegado redirigimos
        if (getProfile() === "Delegado" || getProfile() === "Permiso") {
          return <Redirect to={{ pathname: "/gerente/especial" }} />;
        }
        //Si el rol es colaborador la pagina de inicio es llamados
        if (getProfile() === "Colaborador") {
          return <Redirect to={{ pathname: "/colaborador/llamados" }} />;
        }
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: "/" }} />;
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

// root rout
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
  roles: [
    "Super Administrador",
    "Solicitante",
    "Talento Humano",
  ],
};

//Dashboard Principal
const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  route: PrivateRoute,
  roles: [
    "Super Administrador",
    "Solicitante",
    "Talento Humano",
  ],
  icon: "uil-chart-growth",
  component: Dashboard,
  header: "Menú",
};

// Administracion
const administracionRoutes = {
  path: "/administracion",
  name: "Administración",
  route: PrivateRoute,
  icon: "uil-cog",
  roles: ["Super Administrador"],
  children: [
    {
      path: "/administracion/origenes",
      name: "Origen",
      component: OrigenesGrid,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/administracion/enunciados",
      name: "Enunciados",
      component: EnunciadosGrid,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/administracion/causas",
      name: "Causas",
      component: CausasGrid,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/administracion/acciones",
      name: "Acciones",
      component: AccionesGrid,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/administracion/solicitantes_origenes",
      name: "Solicitante-Origen",
      component: SolicitanteOrigenesGrid,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/administracion/formularios",
      name: "Formularios",
      component: FormulariosGrid,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/administracion/tthh",
      name: "Permisos",
      component: TthhGrid,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/administracion/cargaMasiva",
      name: "Carga Masiva",
      component: PermisoCargaMasivaGrid,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/administracion/imagenes",
      name: "Imágenes",
      component: Imagenes,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
  ],
};

//Reportes
const reportesRoutes = {
  path: "/reportes",
  name: "Reportes",
  route: PrivateRoute,
  icon: "uil-file-alt",
  roles: [
    "Super Administrador",
    "Solicitante",
    "Talento Humano",
  ],
  children: [
    {
      path: "/reportes/reporteGeneral",
      name: "Reporte General",
      component: ReporteGeneral,
      route: PrivateRoute,
      roles: [
        "Super Administrador",
        "Solicitante",
        "Talento Humano",
      ],
    },
    {
      path: "/reportes/reporteDescuentos",
      name: "Reporte Descuentos",
      component: ReporteDescuentos,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/reportes/reporteResumen",
      name: "Reporte Resumen",
      component: ReporteResumen,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/reportes/reporteColaborador",
      name: "Reporte Colaborador",
      component: ReporteColaborador,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
    {
      path: "/reportes/reporteMemorandum",
      name: "Reporte Memorándums",
      component: ReporteMemorandum,
      route: PrivateRoute,
      roles: [
        "Super Administrador",
        "Solicitante",
        "Talento Humano",
      ],
    },
    {
      path: "/reportes/reporteCausaEnun",
      name: "Reporte Causas Enunciados",
      component: ReporteCausasEnun,
      route: PrivateRoute,
      roles: ["Super Administrador"],
    },
  ],
};
//Colaborador
const colaboradorRoutes = {
  path: "/colaborador/llamados",
  name: "Mis Llamados de Atención",
  route: PrivateRoute,
  roles: ["Colaborador"],
  icon: "uil-lightbulb",
  component: LlamadosColaboradorGrid,
};
const colaboradorMemorandums = {
  path: "/colaborador/memorandums",
  name: "Mis Memorándums",
  route: PrivateRoute,
  roles: ["Colaborador"],
  icon: "uil-file-alt",
  component: MemoColaboradorGrid,
};

//TTHH - Delegado - Permiso
const TTHHRoutes = {
  path: "/gerente/especial",
  name: "Llamados de Atención",
  route: PrivateRoute,
  roles: ["Talento Humano", "Delegado", "Permiso"],
  icon: "uil-lightbulb",
  component: LlamadosGerenteGrid,
};
//Grid memorandums para perfiles especiales
const MemorandumsEspecial = {
  path: "/gerente/memorandum/especial",
  name: "Memorándums",
  route: PrivateRoute,
  roles: ["Delegado", "Permiso"],
  icon: "uil-file-alt",
  component: MemoSolicitanteGrid,
};
//Solicitante
const gerenteRoutes = {
  path: "/gerente",
  name: "Llamados de Atención",
  route: PrivateRoute,
  icon: "uil-lightbulb",
  roles: ["Solicitante"],
  children: [
    {
      path: "/gerente/llamados",
      name: "Mis Llamados",
      component: LlamadosColaboradorGrid,
      route: PrivateRoute,
    },
    {
      path: "/gerente/empresarial",
      name: "Llamados",
      component: LlamadosGerenteGrid,
      route: PrivateRoute,
    },
  ],
};

const parametrizacionRoutes = {
  path: "/parametros",
  name: "Parametrización",
  route: PrivateRoute,
  icon: "uil-cog",
  roles: ["Solicitante"],
  children: [
    {
      path: "/parametros/delegacion",
      name: "Delegación",
      component: DelegarGrid,
      route: PrivateRoute,
    },
    {
      path: "/parametros/permiso",
      name: "Permisos",
      component: PermisoGrid,
      roles: ["Solicitante"],
      route: PrivateRoute,
    },
  ],
};

const memorandumsRoutes = {
  path: "/memorandums",
  name: "Memorándums",
  route: PrivateRoute,
  icon: "uil-file-alt",
  roles: ["Solicitante"],
  children: [
    {
      path: "/memorandums/colaborador",
      name: "Mis Memorándums",
      component: MemoColaboradorGrid,
      route: PrivateRoute,
    },
    {
      path: "/memorandums/solicitante",
      name: "Memorándums",
      component: MemoSolicitanteGrid,
      route: PrivateRoute,
    },
  ],
};

const appRoutes = [
  dashboardRoutes,
  colaboradorRoutes,
  colaboradorMemorandums,
  gerenteRoutes,
  memorandumsRoutes,
  parametrizacionRoutes,
  TTHHRoutes,
  MemorandumsEspecial,
  // perfilRoutes,
];

// auth
const authRoutes = {
  path: "/account",
  name: "Auth",
  children: [
    {
      path: "/account/login",
      name: "Login",
      component: Login,
      route: Route,
    },
    {
      path: "/account/logout",
      name: "Logout",
      component: Logout,
      route: Route,
    },
    {
      path: "/perfil",
      name: "Perfil",
      component: SeleccionarPerfil,
      route: Route,
    },
  ],
};

//Rutas para formularios
const formRoutes = [
  {
    path: "/administracion/enunciados_form",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: EnunciadoForm,
  },
  {
    path: "/administracion/causas_form",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: CausasForm,
  },
  {
    path: "/administracion/acciones_form",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: AccionesForm,
  },
  {
    path: "/administracion/formularios_form",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: FormulariosForm,
  },
  {
    path: "/gerente/llamado_form",
    route: PrivateRoute,
    roles: [
      "Solicitante",
      "Talento Humano",
      "Delegado",
      "Permiso",
    ],
    component: CrearLlamadoForm,
  },
  {
    path: "/colaborador/llamado_det",
    route: PrivateRoute,
    roles: [
      "Colaborador",
      "Solicitante",
      "Talento Humano",
      "Delegado",
      "Permiso",
    ],
    component: LlamadoFormColaborador,
  },
  {
    path: "/gerente/llamado_det",
    route: PrivateRoute,
    roles: [
      "Solicitante",
      "Talento Humano",
      "Delegado",
      "Permiso",
    ],
    component: LlamadoForm,
  },
  {
    path: "/colaborador/memorandum",
    route: PrivateRoute,
    roles: [
      "Solicitante",
      "Talento Humano",
      "Delegado",
      "Permiso",
      "Colaborador",
    ],
    component: MemorandumDetalle,
  },
  {
    path: "/administracion/errores",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: ErroresEnunciados,
  },
  {
    path: "/administracion/ver_errores",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: VerErrores,
  },
  {
    path: "/colaborador/check_list",
    route: PrivateRoute,
    roles: ["Colaborador", "Solicitante"],
    component: CheckList,
  },
  {
    path: "/tthh/finalizando",
    route: PrivateRoute,
    roles: ["Talento Humano"],
    component: FinalizadoForm,
  },
  {
    path: "/administracion/tthh_form",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: TthhForm,
  },
  {
    path: "/gerente/delegacion_form",
    route: PrivateRoute,
    roles: ["Solicitante"],
    component: DelegarForm,
  },
  {
    path: "/gerente/permiso_form",
    route: PrivateRoute,
    roles: ["Solicitante"],
    component: PermisoForm,
  },
  {
    path: "/administracion/cargaMasiva_form",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: PermisoCargaMasivaForm,
  },
  {
    path: "/gerente/verFormulario",
    route: PrivateRoute,
    roles: ["Solicitante", "Talento Humano", "Delegado", "Permiso"],
    component: FormularioColaborador,
  },
  {
    path: "/memorandums/llamado_form",
    route: PrivateRoute,
    roles: [
      "Solicitante",
      "Talento Humano",
      "Delegado",
      "Permiso",
    ],
    component: CrearLlamadoForm,
  },
  {
    path: "/administracion/origenes_form",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: OrigenesForm,
  },
  {
    path: "/administracion/solicitante_origenes_form",
    route: PrivateRoute,
    roles: ["Super Administrador"],
    component: SolicitanteOrigenesForm,
  },
  {
    path: "/gerente/historial",
    route: PrivateRoute,
    roles: [
      "Solicitante",
      "Talento Humano",
      "Delegado",
      "Permiso",
    ],
    component: HistorialColaboradorGrid,
  },
  {
    path: "/gerente/historial-memorandums",
    route: PrivateRoute,
    roles: [
      "Solicitante",
      "Talento Humano",
      "Delegado",
      "Permiso",
    ],
    component: HistorialMemoColaboradorGrid,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const allRoutes = [
  rootRoute,
  ...appRoutes,
  administracionRoutes,
  reportesRoutes,
  authRoutes,
  ...formRoutes,
];

const authProtectedRoutes = [
  ...appRoutes,
  administracionRoutes,
  reportesRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
