// @flow
import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";
/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }
  const decoded = jwtDecode(user.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn("access token expired");
    return false;
  } else {
    return true;
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const cookies = new Cookies();
  const user = cookies.get("user");
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

/**
 * Retornar el perfil del usuario
 */
const getProfile = () => {
  const { token } = getLoggedInUser();
  const perfil = jwtDecode(token);
  return perfil.perfil;
};

/**
 * Retornar el subPerfil del usuario de TTHH
 */
const getSubProfile = () => {
  const { token } = getLoggedInUser();
  const subPerfil = jwtDecode(token);
  return subPerfil.subPerfil;
};

/**
 * Retornar los perfiles del usuario
 */
const getMultipleProfile = () => {
  const { token } = getLoggedInUser();
  const perfil = jwtDecode(token);
  return perfil.perfiles;
};

export {
  isUserAuthenticated,
  getLoggedInUser,
  getProfile,
  getMultipleProfile,
  getSubProfile,
};
