// @flow
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_USER,
} from "./constants";

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username: string, password: string, tempCedula: string): AuthAction => ({
  type: LOGIN_USER,
  payload: { username, password, tempCedula },
});

export const loginUserSuccess = (user: string): AuthAction => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error: string): AuthAction => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const logoutUser = (history: any): AuthAction => ({
  type: LOGOUT_USER,
  payload: { history },
});
