import Swal from "sweetalert2";

const ERROR_SERVICIO =
  "Error en el servicio web. Contáctese con Soporte Técnico.";
//error en el servicio de Colgados Llamados atencion
const COD_E_0001 = `${ERROR_SERVICIO} COD: E-0001`;
//error en el ws de easyLogin
const COD_E_0002 = `${ERROR_SERVICIO} COD: E-0002`;
//error en el servicio de Login Twiins
const COD_E_0003 = `${ERROR_SERVICIO} COD: E-0003`;

const msg_success = (message = "", title = "") => {
  Swal.fire({
    icon: "success",
    title: title,
    text: message,
  });
};

const msg_error = (message = "", title = null) => {
  Swal.fire({
    icon: "error",
    title: title,
    text: message,
  });
};

const msg_si_no = (message = "") => {
  Swal.fire({
    title: "¿Esta seguro?",
    text: "No se va aplicar ninguna acción",
    showCancelButton: true,
    confirmButtonColor: "#727cf5",
    cancelButtonColor: "#fa5c7c",
    confirmButtonText: "Si",
    cancelButtonText: "No",
  });
};

const msg_confirm_data = (title = "", message = "") => {
  return {
    title: title,
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#727cf5",
    cancelButtonColor: "#727cf5",
    confirmButtonText: "Si",
    cancelButtonText: "Cancelar",
  };
};

export {
  msg_success,
  msg_error,
  msg_si_no,
  COD_E_0001,
  COD_E_0002,
  COD_E_0003,
  msg_confirm_data,
};
